import React from "react";

const ToggleButton = ({ title, name, onChange, defaultChecked }) => {
    return (
        <>
            <input type="checkbox" id="switch" name={name} className="toggle-input" onChange={e => onChange(e)} defaultChecked={defaultChecked}/>
            <label for="switch" className="toggle-label">{title}</label>
        </>
    )
};

export default ToggleButton;