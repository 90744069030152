import React, { useState, Fragment } from "react";
import SideBarItem from "./SideBarItem";

const SideBarHeader = props => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const iconName = isCollapsed ? "down" : "right";
  const toggleHidden = isCollapsed ? "" : "hidden";
  const projects = props.projects.filter(project => project.type === props.type);

  return (
    <Fragment>
      <div className="first-list-item">
        <span className={`clickable`}>
          <i className={`fas fa-caret-${iconName}`} onClick={() => setIsCollapsed(!isCollapsed)} />
        </span>
        <span className="header-text"> {props.type}</span>
      </div>
      <div className={toggleHidden}>
        {projects.map(project => (
          <SideBarItem project={project} updateState={props.updateState} />
        ))}
      </div>
    </Fragment>
  );
};

export default SideBarHeader;
