import axios from "../constants/axiosRequest";
import store from "../store";
import { setAlert } from "../actions/alert";
import logger from "./logger";

const hostName = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

export const getUser = async () => {
  try {
    const res = await axios.get(hostName + "/api/auth");
    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getProject = async projectId => {
  try {
    const res = await axios.get(hostName + "/api/projects/" + projectId);

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getProjects = async () => {
  try {
    const res = await axios.get(hostName + "/api/projects");
    const data = res.data;
    const dateNow = (((new Date().getTime() / 1000) / 60) / 60) / 24;

    for (let i = 0; i < data.length; i++) {
      const dateUpdated = (((new Date(data[i].updatedAt).getTime() / 1000) / 60) / 60) / 24;

      if (dateNow - dateUpdated > 30) {
        const project = {
          active: !data[i].active,
          userName: "System Administrator"
        }
        const updatedProject = await editProject(project, data[i].id);
        data[i] = updatedProject.data
      }
    }

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const createProject = async (title, type, typeId, userName) => {
  try {
    const res = await axios.post(hostName + "/api/projects", { title, type, typeId, userName });

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const editProject = async (project, projectId) => {
  try {
    const res = await axios.put(hostName + "/api/projects/" + projectId, project);

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getExperiments = async projectId => {
  try {
    const res = await axios.get(hostName + "/api/projects/" + projectId + "/experiments");

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const createExperiment = async (
  title,
  description,
  documentType,
  projectId,
  instrumentId,
  testId,
  userName,
  visibility
) => {
  try {
    const res = await axios.post(hostName + "/api/projects/" + projectId + "/experiments", {
      title,
      description,
      documentType,
      instrumentId,
      testId,
      userName,
      visibility
    });

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const editExperiment = async (title, description, projectId, experimentId, instrumentId, testId, userName) => {
  try {
    const res = await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
      title,
      description,
      instrumentId,
      testId,
      userName
    });

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getNotebook = async (projectId, experimentId) => {
  try {
    const res = await axios.get(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId);

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const editNotebook = async (title, description, projectId, experimentId, userName) => {
  try {
    const res = await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
      title,
      description,
      userName
    });

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const publishNotebook = async (isPublished, publisher, publishedAt, projectId, experimentId, userName) => {
  try {
    return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
      isPublished,
      publisher,
      publishedAt,
      userName
    });
  } catch (err) {
    handleErrors(err);
  }
};

export const reviewNotebook = async (isReviewed, reviewer, reviewedAt, projectId, experimentId, userName) => {
  try {
    return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
      isReviewed,
      reviewer,
      reviewedAt,
      userName
    });
  } catch (err) {
    handleErrors(err);
  }
};

export const getLogs = async () => {
  try {
    const res = await axios.get(hostName + "/api/logs");

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const createLog = async (logFlag, title, id) => {
  try {
    const user = await axios.get(hostName + "/api/auth");
    const { FirstName, LastName } = user.data;
    const userName = FirstName + " " + LastName;
    let event = "";
    let urlPath = window.location.href;
    let message = "";

    switch (logFlag) {
      case 1:
        event = "Create Project";
        urlPath += "/" + id;
        message = userName + ' created project "' + title + '"';
        break;
      case 2:
        event = "Create Experiment";
        urlPath += "/" + id;
        message = userName + ' created experiment "' + title + '"';
        break;
      case 3:
        event = "Edit Project";
        urlPath += "/" + id;
        message = userName + ' edited project "' + title + '"';
        break;
      case 4:
        event = "Edit Experiment";
        urlPath += "/" + id;
        message = userName + ' made changes to experiment "' + title + '"';
        break;
      case 5:
        event = "Create Template";
        urlPath += "/" + id;
        message = userName + ' created template "' + title + '"';
        break;
      case 6:
        event = "Edit Template";
        urlPath += "/" + id;
        message = userName + ' made changes to experiment "' + title + '"';
        break;
      case 7:
        event = "Upload Experiment";

        if (title.length > 1) {
          message = userName + ' uploaded experiments "';
        } else {
          message = userName + ' uploaded experiment "';
        }

        for (let i = 0; i < title.length; i++) {
          if (i === 0) {
            message += title[i].name + '"';
          } else {
            message += ', "' + title[i].name + '"';
          }
        }
        break;
      case 8:
        event = "Upload Template";

        if (title.length > 1) {
          message = userName + ' uploaded templates "';
        } else {
          message = userName + ' uploaded template "';
        }

        for (let i = 0; i < title.length; i++) {
          if (i === 0) {
            message += title[i].name + '"';
          } else {
            message += ', "' + title[i].name + '"';
          }
        }
        break;
      case 9:
        event = "Publish Experiment";
        message = userName + ' published experiment "' + title + '"';
        break;
      case 10:
        event = "Reviewed Experiment";
        message = userName + ' reviewed experiment "' + title + '"';
        break;
      default:
        break;
    }

    return await axios.post(hostName + "/api/logs", { userName, event, urlPath, message });
  } catch (err) {
    handleErrors(err);
  }
};

export const getTemplates = async () => {
  try {
    const res = await axios.get(hostName + "/api/templates");

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getTemplate = async templateId => {
  try {
    const res = await axios.get(hostName + "/api/templates/" + templateId);

    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const createTemplate = async (title, description, documentType, userName) => {
  try {
    return await axios.post(hostName + "/api/templates", {
      title,
      description,
      documentType,
      userName
    });
  } catch (err) {
    handleErrors(err);
  }
};

export const editTemplate = async (title, description, templateId, userName) => {
  try {
    return await axios.put(hostName + "/api/templates/" + templateId, {
      title,
      description,
      userName
    });
  } catch (err) {
    handleErrors(err);
  }
};

export const loadTemplate = async (templateId, documentType, projectId, experimentId, userName) => {
  try {
    return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
      templateId,
      documentType,
      userName
    });
  } catch (err) {
    handleErrors(err);
  }
};

export const uploadFiles = async (url, data) => {
  try {
    return await axios.post(url, data);
  } catch (err) {
    handleErrors(err);
  }
};

export const uploadImage = async (url, data) => {
  try {
    return await axios.post(url, data);
  } catch (err) {
    handleErrors(err);
  }
};

export const setOpensocialConfig = async (config) => {
  try {
    return await axios.post(`${hostName}/api/opensocial/config`, config);
  } catch (err) {
    handleErrors(err);
  }
};

export const getOpensocialConfig = async () => {
  try {
    return await axios.get(`${hostName}/api/opensocial/config`);
  } catch (err) {
    handleErrors(err);
  }
};

export const getConfig = async (configName) => {
  try {
    return await axios.get(`${hostName}/api/config/${configName}`)
  } catch(err) {
    handleErrors(err);
  }
};

export const setConfig = async (configName, configValue) => {
  try {
    await axios.post(`${hostName}/api/config/${configName}`, {
      configValue
    });  
  } catch (err) {
    handleErrors(err);
  }
};

export const getFileConfig = async (configName) => {
  try {
    return await axios.get(`${hostName}/api/config/file/${configName}`)
  } catch(err) {
    handleErrors(err);
  }
};

export const setFileConfig = async (configName, data) => {
  try {
    await axios.post(`${hostName}/api/config/file/${configName}`, data);  
  } catch (err) {
    handleErrors(err);
  }
};

const handleErrors = (err) => {
  if(err && err.response && err.response.data && err.response.data.errors) {
    const errors = err.response.data.errors;

    if (errors) {
      for (let i = 0; i < errors.length; i++) {
        store.dispatch(setAlert(errors[i].msg, "danger"));
      }
    }  
  } else {
    store.dispatch(setAlert("Server Error", "danger"));
  }
  
  return;
};
