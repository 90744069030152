import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import placeholder from "../../img/Lablynx-logo.png";

const Login = ({ login, isAuthenticated, logo }) => {
  const [formData, setFormData] = useState({
    LoginID: "",
    Password: ""
  });

  const { LoginID, Password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(LoginID, Password);
  };

  if (isAuthenticated) {
    return <Redirect to="/projects" />;
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center logo">
        <div className="col- shadow bg-white rounded panel text-center">
          <span>
            <img src={logo ? `data:image/jpeg;base64, ${ logo }` : placeholder} alt="Client Logo" className="img-fluid" />
          </span>
          <form className="form mt-5" onSubmit={e => onSubmit(e)}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Username"
                name="LoginID"
                value={LoginID}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                name="Password"
                minLength="6"
                value={Password}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className="container row justify-content-center m-0 p-0">
              <input type="submit" className="btn btn-primary login-btn" value="Login" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  logo: state.config.logo
});

export default connect(mapStateToProps, { login })(Login);
