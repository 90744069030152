import React, { Fragment } from "react";
import Project from "./components/workspace/Project";
import Experiment from "./components/workspace/Experiment";
import PrivateRoute from "./components/routing/PrivateRoute";
import Notebook from "./components/workspace/Notebook";
import Template from "./components/workspace/Template.js";
import Search from "./components/workspace/Search.js";
import Log from "./components/workspace/Log";
import LogoutPopup from "./components/layout/LogoutPopup";
import TemplateBuilder from "./components/workspace/TemplateBuilder";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Landing from "./components/workspace/Landing";
import NotFound from "./components/error/NotFound";
import NotAuthorized from "./components/error/NotAuthorized";

const Layout = () => {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <LogoutPopup />
          <Switch>
            <PrivateRoute exact path="/dashboard">
              <Redirect to="/projects" />
            </PrivateRoute>
            <PrivateRoute exact path="/projects" component={Project} />
            <PrivateRoute exact path="/projects/:projectId/experiments" component={Experiment} />
            <PrivateRoute exact path="/projects/:projectId/experiments/:experimentId" component={Notebook} />
            <PrivateRoute exact path="/logs" component={Log} />
            <PrivateRoute exact path="/templates" component={Template} />
            <PrivateRoute exact path="/templates/:templateId" component={TemplateBuilder} />
            <PrivateRoute exact path="/search" component={Search} />
            <Route exact path="/notauthorized" component={NotAuthorized} />
            <Route exact path="/" component={Landing} /> 
            <Route path="*" component={NotFound} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default Layout;
