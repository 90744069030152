import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ExperimentsModal from "./ExperimentsModal";
import CopyModal from "./CopyModal";
import axios from "../../constants/axiosRequest";
import logger from "../../utils/logger";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const ExperimentsCard = ({user, ...props}) => {
  const [tzOption, setTzOption] = useState("");

  useEffect(() => {
    setTzOption(getTimezone(props.tzOption));
  }, [props.tzOption]);

  const handlePdfView = async e => {
    e.preventDefault();
    try {
      const response = await axios(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/projects/${props.projectId}/pdf/${props.data.id}`, {
        method: "GET",
        responseType: "blob"
      });

      const file = new Blob([response.data], {
        type: "application/pdf"
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    } catch (error) {
      logger('error', error);
    }
  };

  return (
    <div className={`col-12 col-md-6 col-lg-4 col-xl-3`}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {props.data.documentType === "pdf" ? (
              <a className="clickable" href="#" onClick={e => handlePdfView(e)}>{props.data.title}</a>
            ) : (
              <Link
                to={"/projects/" + props.projectId + "/experiments/" + props.data.id}
                onClick={e =>
                  props.data.visibility === "Public" || props.data.isPublished || props.data.userId === props.user.UserID
                    ? ""
                    : e.preventDefault()
                }
              >
                {props.data.title}
              </Link>
            )}
          </h5>
          <p className="description-text" readOnly>
            {props.data.description}
          </p>
          <div className="card-footer pl-0 pr-0">
            <div className="row footer">
              <div className="col-">
              <ExperimentsModal
                  edit={props.edit}
                  updateState={props.updateState}
                  projectId={props.projectId}
                  data={props.data}
                />
              </div>
              <div className={`col-`}>
                {props.data.isPublished && (
                  <CopyModal
                    updateState={props.updateState}
                    data={props.data}
                    apiUrl={`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/projects/${props.projectId}/experiments/${props.data.id}/copy`}
                  />
                )}
              </div>
            </div>
            <div className="footer-text">
              <p className="card-text m-0">
                <small className="text-muted">
                  Created by <strong>{props.data.createdBy}</strong> -{" "}
                  <strong>{formatTimezone(props.data.createdAt, tzOption)}</strong>
                </small>
              </p>
              <p className="card-text m-0">
                <small className="text-muted">
                  Last edit by <strong>{props.data.lastUpdateBy}</strong> -{" "}
                  <strong>{formatTimezone(props.data.updatedAt, tzOption)}</strong>
                </small>
              </p>
              {!props.data.isPublished && props.data.visibility === "Private" && (
                <p className="card-text m-0">
                  <small className="user-text">
                    In use by <strong>{props.data.createdBy}</strong>
                  </small>
                </p>
              )}
              {props.data.isPublished && (
                <p className="card-text m-0">
                  <small className="text-muted">
                    Published by <strong>{props.data.publisher}</strong> -{" "}
                    <strong>{formatTimezone(props.data.publishedAt, tzOption)}</strong>
                  </small>
                </p>
              )}
              {props.data.isReviewed && (
                <p className="card-text m-0">
                  <small className="text-muted">
                    Reviewed by <strong>{props.data.reviewer}</strong> -{" "}
                    <strong>{formatTimezone(props.data.reviewedAt, tzOption)}</strong>
                  </small>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  tzOption: state.timezone.tzOption
});

export default connect(mapStateToProps)(ExperimentsCard);
