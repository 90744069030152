import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../constants/axiosRequest";
import TemplatesModal from "./TemplatesModal";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const TemplatesCard = props => {
  const [tzOption, setTzOption] = useState("");

  useEffect(() => {
    setTzOption(getTimezone(props.tzOption));
  }, [props.tzOption]);

  const onDeleteClick = async e => {
    if (window.confirm(`Are you sure you want to delete template ${props.template.title}?`)) {
      await axios.delete(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/templates/${props.templateId}`
      );

      props.updateState();
    }
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Link to={"/templates/" + props.templateId}>{props.template.title}</Link>
          </h5>
          <p className="description-text" readOnly>
            {props.template.description}
          </p>
          <div className="card-footer pl-0 pr-0">
            <div className="row footer">
              <div className="col-">
                <TemplatesModal
                  edit={props.edit}
                  updateState={props.updateState}
                  templateId={props.templateId}
                  template={props.template}
                />
              </div>
              <div className="col- ml-1">
                <button className="btn btn-sm btn-danger ml-1" onClick={e => onDeleteClick(e)}>
                  Delete
                </button>
              </div>
            </div>
            <div className="footer-text">
              <p class="card-text m-0">
                <small class="text-muted">
                  Created by <strong>{props.template.createdBy}</strong> -{" "}
                  <strong>{formatTimezone(props.template.createdAt, tzOption)}</strong>
                </small>
              </p>
              <p class="card-text m-0">
                <small class="text-muted">
                  Last Edit by <strong>{props.template.lastUpdateBy}</strong> -{" "}
                  <strong>{formatTimezone(props.template.updatedAt, tzOption)}</strong>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tzOption: state.timezone.tzOption
});

export default connect(mapStateToProps)(TemplatesCard);
