import React, { useState, useEffect, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../constants/axiosRequest";
import ExperimentsCard from "../layout/ExperimentsCard";
import ExperimentsModal from "../layout/ExperimentsModal";
import FileUploadModal from "../layout/FileUploadModal";
import SearchField from "../layout/SearchField";
import SideBar from "../layout/SideBar";
import Pagination from "../layout/Pagination";
import paginate from "../../utils/paginate";
import { getProject, getProjects, getExperiments, uploadFiles } from "../../utils/apiCall";

const Experiment = ({ match, location, user }) => {
  const [project, setProject] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [experiments, setExperiments] = useState([]);
  const [data, setData] = useState({});
  const [instruments, setInstruments] = useState([]);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [updateState, setUpdateState] = useState(false);

  const projectId = match.params.projectId;

  useEffect(() => {
    const fetchData = async () => {
      if (location.project) {
        setProject(location.project);
      } else {
        const project = await getProject(projectId);
        setProject(project);

        if (!project) {
          return
        }
      }

      const experiments = await getExperiments(projectId);

      if (!experiments) {
        return
      }

      let projects = await getProjects();
      projects = projects.filter(project => project.active);
      const projectExperiments = await axios.get(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`
      );
      let typesList = [];

      for (let project of projects) {
        const filteredExperiments = projectExperiments.data.filter(experiment => experiment.projectId === project.id);
        project.experiments = filteredExperiments;

        if (!typesList.includes(project.type)) {
          typesList.push(project.type);
        }
      }

      let pageNumber = page;

      for (let i = 0; i < pageNumber; i++) {
        if (!pages[pageNumber] && pageNumber > 0) {
          pageNumber--;
        } else {
          break;
        }
      }

      const pages = paginate(experiments.reverse());
      const userId = user.UserID;
      const instruments = await axios.get(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/instruments/` + userId
      );
      const tests = await axios.get(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/tests/` + userId
      );

      setPage(pageNumber);
      setProjects(projects.reverse());
      setProjectTypes(typesList);
      setInstruments(instruments.data);
      setTests(tests.data);
      setExperiments(pages);
      setData(experiments);
      setLoading(false);
    };

    fetchData();
  }, [updateState]);

  if (!project) {
    return <Redirect to="/projects" />;
  }

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSearch = async (queryParam, query, filter) => {
    let pages;
    if (filter === "All") {
      const res = data.filter((experiment) => experiment[queryParam].toLowerCase().includes(query.toLowerCase()));
      pages = paginate(res);
    } else if (filter === "Not published") {
      const res = data.filter(
        (experiment) =>
          experiment[queryParam].toLowerCase().includes(query.toLowerCase()) && experiment.isPublished === false
      );
      pages = paginate(res);
    } else if (filter === "Published") {
      const res = data.filter(
        (experiment) =>
          experiment[queryParam].toLowerCase().includes(query.toLowerCase()) && experiment.isPublished === true
      );
      pages = paginate(res);
    } else if (filter === "Not reviewed") {
      const res = data.filter(
        (experiment) =>
          experiment[queryParam].toLowerCase().includes(query.toLowerCase()) &&
          experiment.isPublished === true &&
          experiment.isReviewed === false
      );
      pages = paginate(res);
    } else {
      const res = data.filter(
        (experiment) =>
          experiment[queryParam].toLowerCase().includes(query.toLowerCase()) && experiment.isReviewed === true
      );
      pages = paginate(res);
    }
    setExperiments(pages);
  };

  const spinner = (
    <div className="d-flex justify-content-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {loading && spinner}
      {!loading && (
        <div className="container-fluid white-background">
          <div className="row">
            <div className="col-lg-2 hidden-md">
              <div className="side-bar">
                <SideBar projects={projects} projectTypes={projectTypes} updateState={() => setUpdateState(!updateState)} />
              </div>
            </div>
            <div className="col-12 col-lg-10 workspace">
              <div className="container-fluid">
                <nav className="navbar navbar-expand-xl navbar-light">
                  <h3>{project.title}</h3>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto ml-auto">
                      <li className="nav-item mr-3">
                        <ExperimentsModal
                          edit={false}
                          instruments={instruments}
                          tests={tests}
                          updateState={() => setUpdateState(!updateState)}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          project={project}
                          projectId={projectId}
                        />
                      </li>
                      <li className="nav-item">
                        <FileUploadModal
                          updateState={() => setUpdateState(!updateState)}
                          logFlag={0}
                          uploadFiles={async (data, userName) =>
                            await uploadFiles(
                              `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/projects/` +
                              projectId +
                              "/experiments/upload?userName=" +
                              userName,
                              data
                            )
                          }
                        />
                      </li>
                    </ul>
                    <ul className="navbar-nav">
                      <li className="nav-item mr-3">
                        <Link to="/projects" className="link">
                          <i className="fas fa-project-diagram" />
                          <span> Projects</span>
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <Link to="/templates" className="link">
                          <i className="fas fa-copy" />
                          <span> Templates</span>
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <Link to="/search" className="link">
                          <i className="fas fa-search" />
                          <span> Search</span>
                        </Link>
                      </li>
                      <li className="nav-item mr-4">
                        <Link to="/logs" className="link">
                          <i className="fas fa-list" />
                          <span> Activity Log</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <SearchField
                          useFilter={true}
                          handleSearch={(queryParam, query, filter) => handleSearch(queryParam, query, filter)}
                        />
                      </li>
                    </ul>
                  </div>
                </nav>
                <div className="row">
                  {experiments.length > 0 ? (
                    experiments[page].map((experiment) => (
                      <ExperimentsCard
                        user={user}
                        edit={true}
                        data={experiment}
                        instruments={instruments}
                        tests={tests}
                        updateState={() => setUpdateState(!updateState)}
                        projectId={projectId}
                      />
                    ))
                  ) : (
                      <h1>No Experiments Found...</h1>
                    )}
                </div>
                {experiments.length > 1 && <Pagination data={experiments} handlePage={(page) => handlePage(page)} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  tzOption: state.timezone.tzOption,
  user: state.auth.user
});

export default connect(mapStateToProps)(Experiment);
