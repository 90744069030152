import axios from "../constants/axiosRequest";
import { setAlert } from "./alert";
import { LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED, AUTH_ERROR, LOGOUT } from "./types";
import setAuthToken from "../utils/setAuthToken";
import logger from "../utils/logger";

export const loadUser = () => async dispatch => {

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/auth`, {withCredentials: true});
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

export const login = (loginId, password) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ LoginID: loginId, Password: password });

  try {
    const res = await axios.post(
      `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/auth`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });


    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;


    if (errors) {
      for (let error in errors) {
        dispatch(setAlert(errors[error], "danger"));
      }
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const logout = () => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "text/plain"
    }
  };
  const authTok = localStorage.getItem('token');
  dispatch({ type: LOGOUT });

  const res = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/logout`
  ).then(response => window.location.replace(response.data)
  ).catch(err => {
    //handle bade requests
    logger('error', err)
  });


};
