import React, { useState, useEffect, Fragment } from "react";
import { ButtonGroup, ButtonToolbar, Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux"
import { createExperiment, editExperiment, createLog } from "../../utils/apiCall";
import word from "../../img/Microsoft_Word_logo.png";
import excel from "../../img/288px-Microsoft_Excel_Logo.svg.png";
import logger from "../../utils/logger";


const ExperimentsModal = ({user, ...props}) => {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    title: props.data ? props.data.title : "",
    description: props.data ? props.data.description : "",
    testId: props.data ? props.data.TESTID : -2,
    instrumentId: props.data ? props.data.ISNTRUMENTID : -2,
    documentType: props.data ? props.data.documentType : "docx",
    visibility: props.data ? props.data.visibility : "Public"
  });
  const [userName, setUserName] = useState(`${user.FirstName} ${user.LastName}`);
  const [updateState, setUpdateState] = useState(true);



  useEffect(() => {
    const fetchData = async () => {

      setFormData({
        title: props.data ? props.data.title : "",
        description: props.data ? props.data.description : "",
        testId: props.data ? props.data.TESTID : -2,
        instrumentId: props.data ? props.data.ISNTRUMENTID : -2,
        documentType: props.data ? props.data.documentType : "docx",
        visibility: props.data ? props.data.visibility : "Public"
      })
    };

    fetchData();
  }, [updateState]);

  const { title, description, documentType, testId, instrumentId, visibility } = formData;
  const projectId = props.projectId;

  let buttonIcon = "plus";
  if (props.edit) {
    buttonIcon = "edit";
  }

  const onClick = () => {
    setUpdateState(!updateState);
    setModalShow(true);
  }

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    if (props.edit) {
      setModalShow(false);
      const experiment = await editExperiment(
        title,
        description,
        projectId,
        props.data.id,
        instrumentId,
        testId,
        userName,
        visibility
      );

      if (experiment !== undefined) {
        await createLog(4, title, experiment.id);
      }

      props.updateState();
    } else {
      setModalShow(false);

      if (props.project.type !== "Instrument") {
        const experiment = await createExperiment(
          title,
          description,
          documentType,
          projectId,
          instrumentId,
          testId,
          userName,
          visibility
        );

        if (experiment !== undefined) {
          await createLog(2, title, experiment.id);
        }
      } else {
        const experiment = await createExperiment(
          title,
          description,
          documentType,
          projectId,
          props.project.typeId,
          testId,
          userName,
          visibility
        );

        if (experiment !== undefined) {
          await createLog(2, title, experiment.id);
        }
      }

      props.updateState();
    }
  };

  return (
    <ButtonToolbar>
      <Button variant="primary" className="modal-button btn-sm" onClick={() => onClick()}>
        <i className={`fas fa-${buttonIcon}`} /> {props.edit ? "Edit" : <span> New Experiment</span>}
      </Button>
      <Modal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="form-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="form-modal">
            <i className={`fas fa-${buttonIcon}`} /> {props.edit ? "Edit Experiment" : "New Experiment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => onSubmit(e)}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                name="title"
                value={formData.title}
                placeholder="Enter Title"
                onChange={e => onChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                placehoder="Enter Description"
                onChange={e => onChange(e)}
              />
            </Form.Group>
            {!props.edit && (
              <Fragment>
                <Form.Group controlId="formTest">
                  <Form.Label>Test</Form.Label>
                  <Form.Control as="select" name="testId" value={formData.testId} onChange={e => onChange(e)}>
                    <option value={-2}>N/A</option>
                    {props.tests ? props.tests.map(test => <option value={test.TESTID}>{test.TESTNAME}</option>) : ""}
                  </Form.Control>
                </Form.Group>
                {props.project.type !== "Instrument" && (
                  <Form.Group controlId="formInstrument">
                    <Form.Label>Instrument</Form.Label>
                    <Form.Control
                      as="select"
                      name="instrumentId"
                      value={formData.instrumentId}
                      onChange={e => onChange(e)}
                    >
                      <option value={-2}>N/A</option>
                      {props.instruments
                        ? props.instruments.map(instrument => (
                          <option value={instrument.INSTRUMENTID}>{instrument.INSTRUMENTNAME}</option>
                        ))
                        : ""}
                    </Form.Control>
                  </Form.Group>
                )}
                <Form.Group controlId="formVisibility">
                  <Form.Label>Visibility</Form.Label>
                  <Form.Control as="select" name="visibility" value={formData.visibility} onChange={e => onChange(e)}>
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formDocumentTypeButton">
                  <ButtonGroup aria-label="Document Type">
                    <Button
                      className="document-button"
                      variant="secondary"
                      onClick={() => setFormData({ ...formData, documentType: "docx" })}
                    >
                      <span>Word</span>
                      <br />
                      <img className="icon" src={word} alt="icon" />
                    </Button>
                    <Button
                      className="document-button"
                      variant="secondary"
                      onClick={() => setFormData({ ...formData, documentType: "xlsx" })}
                    >
                      <span>Excel</span>
                      <br />
                      <img className="icon" src={excel} alt="icon" />
                    </Button>
                  </ButtonGroup>
                </Form.Group>
              </Fragment>
            )}
            <Container>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    {props.edit ? "Edit" : "Create"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => setModalShow(false)}>Close</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </ButtonToolbar>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(ExperimentsModal);
