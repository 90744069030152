import { GET_CONFIG, SET_CONFIG, GET_FILE_CONFIG, SET_FILE_CONFIG } from "./types";
import { getConfig, setConfig, getFileConfig, setFileConfig } from "../utils/apiCall";

export const getConfiguration = (configName) => async dispatch => {
    const config = await getConfig(configName);
    const configValue = config.data;

    let payload = {};
    payload[configName] = configValue;

    dispatch({
        type: GET_CONFIG,
        payload
    });
};

export const setConfiguration = (configName, configValue) => async dispatch => {
    await setConfig(configName, configValue);
    
    let payload = {};
    payload[configName] = configValue;

    dispatch({
        type: SET_CONFIG,
        payload
    });
};

export const getFileConfiguration = (configName) => async dispatch => {
    const config = await getFileConfig(configName);
    const configValue = config.data;

    let payload = {};
    payload[configName] = configValue;

    dispatch({
        type: GET_FILE_CONFIG,
        payload
    });
};

export const setFileConfiguration = (configName, data) => async dispatch => {
    const configValue = await setFileConfig(configName, data);

    let payload = {};
    payload[configName] = configValue;

    dispatch({
        type: SET_FILE_CONFIG,
        payload
    });
};