import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLogs, getUser } from "../../utils/apiCall";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const Logs = (props) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tzOption, setTzOption] = useState("");

  let user = {};

  useEffect(() => {
    setTzOption(getTimezone(props.tzOption));
  }, [props.tzOption]);

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      } else {
        user = await getUser();
        localStorage.setItem("user", JSON.stringify(user));
      }

      const logs = await getLogs();

      let logList = [];
      let list = [];
      let prevDate = logs.length > 0 ? formatTimezone(logs[0].createdAt, tzOption).split(",")[0].trim() : "";
      
      for (let i = 0; i < logs.length; i++) {
        let date = formatTimezone(logs[i].createdAt, tzOption).split(",")[0].trim();
        if (date === prevDate) {
          list.push(logs[i]);
        } else {
          prevDate = date;
          logList.push(list);
          list = [];
          list.push(logs[i]);
        }
      }

      list.length > 0 ? logList.push(list) : (logList = []);

      setLogs(logList);
      setLoading(false);
    };

    fetchData();
  }, []);

  const spinner = (
    <div className="d-flex justify-content-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {loading && spinner}
      {!loading && (
        <div className="container-fluid white-background">
          <div className="row">
            <div className="col-12 workspace">
              <nav className="navbar navbar-expand-xl navbar-light">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item mr-3">
                      <Link to="/projects" className="sub-nav-item link">
                        <i className="fas fa-project-diagram" /> Projects
                      </Link>
                    </li>
                    <li className="nav-item mr-3">
                      <Link to="/templates" className="sub-nav-item link">
                        <i className="fas fa-copy" /> Templates
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/search" className="sub-nav-item link">
                        <i className="fas fa-search" /> Search
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="row">
                <div className="col-12">
                  <ul>
                    {logs.length > 0
                      ? logs.map(arr => (
                          <li>
                            <strong>{formatTimezone(arr[0].createdAt, tzOption).split(",")[0].trim()}</strong>
                            <ul>
                              {arr.map(log => (
                                <li>{formatTimezone(log.createdAt, tzOption).split(",")[1].trim() + " - " + log.message}</li>
                              ))}
                            </ul>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  tzOption: state.timezone.tzOption
});

export default connect(mapStateToProps)(Logs);
