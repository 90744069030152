export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const PROJECTS_LOADED = "PROJECTS_LOADED";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
export const PROJECT_CREATED = "PROJECT_CREATED";
export const EXPERIMENTS_LOADED = "EXPERIMENTS_LOADED";
export const EXPERIMENTS_ERROR = "EXPERIMENTS_ERROR";
export const EXPERIMENT_CREATED = "EXPERIMENT_CREATED";
export const APPLINKS_SET = "APPLINKS_SET";
export const APPLINKS_ERROR = "APPLINKS_ERROR";
export const APPLINKS_REMOVE = "APPLINKS_REMOVE";
export const SET_TIMEZONE = "SET_TIMEZONE";
export const GET_CONFIG = "GET_CONFIG";
export const SET_CONFIG = "SET_CONFIG";
export const GET_FILE_CONFIG = "GET_FILE_CONFIG";
export const SET_FILE_CONFIG = "SET_FILE_CONFIG";
