import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ProjectsModal from "./ProjectsModal";
import { editProject } from "../../utils/apiCall";
import { formatTimezone, getTimezone } from "../../utils/timezone";


const ProjectsCard = props => {
  const [tzOption, setTzOption] = useState("");

  useEffect(() => {
    setTzOption(getTimezone(props.tzOption));
  }, [props.tzOption]);
  
  const isActive = async (e) => {
    e.preventDefault();

    if (window.confirm(`Are you sure you want to set project ${props.data.title} to ${props.data.active ? "inactive" : "active"}?`)) {
      const project = {
        active: !props.data.active,
        userName: `${props.user.FirstName} ${props.user.LastName}`
      }
      await editProject(project, props.data.id);
      props.updateState();
    }
  }

  return (
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Link to={{ pathname: "/projects/" + props.data.id + "/experiments", project: props.data }}>
              {props.data.title}
            </Link>
          </h5>
          <div className="card-footer pl-0 pr-0">
            <div className="row footer">
              <div className="col-4">
                <ProjectsModal
                  edit={true}
                  updateState={props.updateState}
                  projectId={props.data.id}
                  title={props.data.title}
                  typeId={props.data.typeId}
                  type={props.data.type}
                />
              </div>
              <div className="col-8 float-left">
                <label for="checkbox-active">Active</label>
                <input className="checkbox-active" type="checkbox" onClick={(e) => isActive(e)} value={props.data.active} checked={props.data.active} />
              </div>
            </div>
            <div className="footer-text">
              <p class="card-text m-0">
                <small class="text-muted">
                  Created by <strong>{props.data.createdBy}</strong> -{" "}
                  <strong>{formatTimezone(props.data.createdAt, tzOption)}</strong>
                </small>
              </p>
              <p class="card-text m-0">
                <small class="text-muted">
                  Last edit by <strong>{props.data.lastUpdateBy}</strong> -{" "}
                  <strong>{formatTimezone(props.data.updatedAt, tzOption)}</strong>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tzOption: state.timezone.tzOption
});

export default connect(mapStateToProps)(ProjectsCard);
