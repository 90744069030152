import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../constants/axiosRequest";
import placeholder from "../../img/Lablynx-logo.png";

const Landing = () => {
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const logo = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/config/logo`);

      if (logo) {
        setLogo(logo.data);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center logo">
        <div className="col- shadow bg-white rounded panel text-center">
          <span>
            <img src={logo ? `data:image/jpeg;base64, ${ logo }` : placeholder} alt="Client Logo" className="img-fluid" />
          </span>
          <div className="container row justify-content-center m-0 mt-5">
            <Link to="/login" className="btn btn-primary">
              Login
            </Link>
          </div>
          <div className="container row justify-content-center m-0 mt-5">
            <button className="btn btn-primary" onClick={() => window.location = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/login`}>
              Login With OpenSocial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



const mapStateToProps = state => ({
  logo: state.config.logo
})

export default connect(mapStateToProps)(Landing);
