import React, { useState, useEffect } from "react";
import {  Dropdown } from "react-bootstrap";
import { getUser } from "../../utils/apiCall";
import axios from "../../constants/axiosRequest";
import { useSelector } from "react-redux";

const AppLinks = (props) => {
  //State setup
  let [sites, setSites] = useState({})
  let userState = useSelector(state=>state.auth.user)

  //Post Render hook
  useEffect(() => {
    const fetchData = async () => {
      sites = await getSites();
      setSites(sites);
    }
    fetchData();
  }, [userState])
  
  const getSites = async () => {
    const sitelist = await axios.post(
      `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/applinks`
      ).then((res) => {
        if (!res.data.success) {
          return([{
            "login_url":"#",
            "identity":res.data.msg
          }])
        } else {
          return res.data.sites;
        }
      }).catch((err)=>{
        return([{
          "login_url":"#",
          "identity":"Could not load sites from Server"
        }])
      });
      return(sitelist);
  }

  return (
    <Dropdown className="app-links-dropdown"> 
    <Dropdown.Toggle className="app-links-dropdown-toggle" id="dropdown-basic" >
        Site List
    </Dropdown.Toggle>

    <Dropdown.Menu>
    {sites.length > 0 ? (
        sites.map((site) => (
          <Dropdown.Item key={site.identity} href={site.login_url}>
            <span key={site.identity}>{site.site_name}</span>
          </Dropdown.Item>
          ))
        ) : (
          <div> No sites found...</div>
        )}
        
    </Dropdown.Menu>
</Dropdown> 

  );
};

export default AppLinks;
