import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setConfiguration, setFileConfiguration } from "../../actions/config";
import { setAlert } from "../../actions/alert";
import ToggleButton from "../layout/ToggleButton";
import Spinner from "../layout/Spinner";
import placeholder from "../../img/Lablynx-logo.png";

const AdminPanel = ({ clientName, logo, loginText, loading, displayLoginText, setConfiguration, setFileConfiguration, setAlert, user }) => {
  const [formData, setFormData] = useState({
    clientName,
    logo: "",
    loginText,
    displayLoginText
  });
  const [newLogo, setNewLogo] = useState(logo ? `data:image/jpeg;base64, ${ logo }` : placeholder);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await setConfiguration("clientName", formData.clientName);
      await setConfiguration("loginText", formData.loginText);
      await setConfiguration("displayLoginText", (formData.displayLoginText));

      if(formData.logo !== "") {
        await setFileConfiguration("logo", formData.logo);
      }
  
      await setAlert("Successfully Saved", "success", 1500);  
    } catch (err) {
      await setAlert("Error Saving Changes", "danger", 1500);
    }
  }

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleCheckboxChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.checked });

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    let data = new FormData();
    data.append("file", file);
    setFormData({ ...formData, [e.target.name]: data });
    setNewLogo(URL.createObjectURL(file));
  }

  return (
    <>
      {loading && <Spinner/>}
      {!loading && (user.UserID === 0 || user.UserID === -1) &&
        <div className="container-fluid white-background display-overflow">
          <nav className="navbar navbar-expand-xl navbar-light">
            <h3>Admin Panel</h3>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mr-3" key="projects">
                  <Link to="/projects" className="sub-nav-item link">
                    <i className="fas fa-project-diagram" /> Projects
                  </Link>
                </li>
                <li className="nav-item mr-3" key="templates">
                  <Link to="/templates" className="sub-nav-item link">
                    <i className="fas fa-copy" /> Templates
                  </Link>
                </li>
                <li className="nav-item mr-3" key="search">
                  <Link to="/search" className="sub-nav-item link">
                    <i className="fas fa-search" /> Search
                  </Link>
                </li>
                <li className="nav-item" key="logs">
                  <Link to="/logs" className="link">
                    <i className="fas fa-list" />
                    <span> Activity Log</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className="container-fluid center-container">
            <Form onSubmit={e => handleSubmit(e)} encType="multipart/form-data">
                <Form.Group className="mb-3 p-2 border-top">
                  <Form.Label><strong>Client Name</strong></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="clientName"
                    value={formData.clientName}
                    placeholder="Enter Client Name"
                    onChange={e => handleChange(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 p-2 border-top">
                  <Form.Label><strong>Client Logo</strong></Form.Label>
                  <div className="mb-1 panel">
                    { newLogo ?
                      <img src={newLogo} className="img-fluid"/>
                      :
                      "No Logo"
                    }
                  </div>
                  <Form.Control 
                    id="file-upload"
                    type="file"
                    name="logo"
                    onChange={e => handleLogoChange(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 p-2 border-top">
                  <Form.Label><strong>Login Text</strong></Form.Label>
                  <Form.Control
                    type="text"
                    name="loginText"
                    value={formData.loginText}
                    placeholder="Enter Text"
                    onChange={e => handleChange(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 p-2 border-top">
                  <Form.Label><strong>Display After Login</strong></Form.Label>
                  <ToggleButton
                    title="test"
                    name="displayLoginText"
                    onChange={e => handleCheckboxChange(e)}
                    defaultChecked={formData.displayLoginText}
                  />
                </Form.Group>
                <Form.Group className="mb-3 p-2 border-top">
                  <div className="text-center mb-5">
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </div>
                </Form.Group>
            </Form>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = state => ({
  clientName: state.config.clientName,
  logo: state.config.logo,
  loginText: state.config.loginText,
  displayLoginText: state.config.displayLoginText,
  loading: state.config.loading,
  user: state.auth.user
})

export default connect(mapStateToProps, { setConfiguration, setFileConfiguration, setAlert })(AdminPanel);
