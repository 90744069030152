import React, { useState, useEffect, Fragment }  from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getUser } from "../../utils/apiCall";
import store from "../../store";
import logger from "../../utils/logger"

const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading }, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(isAuthenticated);
  const [isLoading, setLoading] = useState(loading);

  useEffect(() =>{
    const fetchData = async () =>
    {
      if(!authenticated){
        await getUser().then((resp) => !resp ? setAuthenticated(false) : setAuthenticated(true)).catch((err) => logger.log('error', "Failed to authenticate"));
        setLoading(store.getState().auth.isLoading)
      } else {setLoading(false)}
    }

    fetchData()
  })

  const spinner = (
    <div className="d-flex justify-content-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {isLoading && spinner}
      {!isLoading && !authenticated && <Redirect to="/" />}
      {!isLoading && authenticated && 
        <Route
          {...rest}
          render={props=> {
              if(!loading && isAuthenticated) {
                return <Component {...props}/>
              } else if(!loading && !isAuthenticated) {
                return <Redirect to="/" />
              } else {
                return <Spinner />
              }
            }
          }/>
      }
    </Fragment>
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
