import React, { useState, useEffect } from "react";
import { ButtonToolbar, Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux"
import { createProject, createLog, editProject } from "../../utils/apiCall";
import { projectType } from "../../constants/projectType";
import logger from "../../utils/logger";

const ProjectsModal = ({user, ...props}) => {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    title: props.title ? props.title : "",
    type: props.type ? props.type : projectType.TEST_REQUEST,
    typeId: props.typeId ? props.typeId : -2
  });

  const [updateState, setUpdateState] = useState(true);
  const { title, type, typeId } = formData;
  const userName = `${user.FirstName} ${user.LastName}`

  useEffect(() => {
    const fetchData = async () => {
      setFormData({
        title: props.title ? props.title : "",
        type: props.type ? props.type : projectType.TEST_REQUEST,
        typeId: props.typeId ? props.typeId : -2
      });
    };

    fetchData();
  }, [updateState]);

  let buttonIcon = "plus";
  if (props.edit) {
    buttonIcon = "edit";
  }

  const onClick = () => {
    setUpdateState(!updateState);
    setModalShow(true);
  }

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    if (props.edit) {
      setModalShow(false);
      const projectData = {
        title,
        userName
      };
      const project = await editProject(projectData, props.projectId);

      if (project !== undefined) {
        await createLog(3, title, project.id);
      }

      props.updateState();
    } else {
      setModalShow(false);
      const project = await createProject(title, type, typeId, userName);

      if (project !== undefined) {
        await createLog(1, title, project.id);
      }

      props.updateState();
    }
  };

  return (
    <ButtonToolbar>
      <Button variant="primary" className="modal-button btn-sm" onClick={e => onClick(e)}>
        <i className={`fas fa-${buttonIcon}`} />
        {props.edit ? "Edit" : <span> New Project</span>}
      </Button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="md" aria-labelledby="form-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="form-modal">
            <i className={`fas fa-${buttonIcon}`} /> {props.edit ? "Edit Project" : "New Project"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => onSubmit(e)}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                name="title"
                placeholder="Enter Title"
                value={formData.title}
                onChange={e => onChange(e)}
              />
            </Form.Group>
            {!props.edit && (
              <Form.Group controlId="formType">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" name="type" value={formData.type} onChange={e => onChange(e)}>
                  {Object.values(projectType).map(type => <option value={type}>{type}</option>)}
                </Form.Control>
              </Form.Group>
            )}
            {formData.type === "Instrument" && !props.edit && (
              <Form.Group controlId="formTypeId">
                <Form.Label>Instrument</Form.Label>
                <Form.Control as="select" name="typeId" value={formData.typeId} onChange={e => onChange(e)}>
                  <option value={-2}>N/A</option>
                  {props.instruments
                    ? props.instruments.map(instrument => (
                      <option value={instrument.INSTRUMENTID}>{instrument.INSTRUMENTNAME}</option>
                    ))
                    : ""}
                </Form.Control>
              </Form.Group>
            )}
            <Container>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    {props.edit ? "Edit" : "Create"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => setModalShow(false)}>Close</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </ButtonToolbar>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(ProjectsModal);
