import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const SideBarItem = props => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const iconName = isCollapsed ? "down" : "right";
  const toggleHidden = isCollapsed ? "" : "hidden";

  return (
    <Fragment>
      <div className="list-item" key={props.project.id}>
        <div className="side-bar-item">
          <span className={`clickable ${props.project.experiments && props.project.experiments.length > 0 ? "" : "not-visible"}`}>
            <i className={`fas fa-caret-${iconName}`} onClick={() => setIsCollapsed(!isCollapsed)} />
          </span>
          <Link
            to={"/projects/" + props.project.id + "/experiments"}
            onClick={props.updateState}
            className="react-link"
          >
            {" " + props.project.title}
          </Link>
        </div>
      </div>
      <div className={`sub-list ${toggleHidden}`}>
        {props.project.experiments ? props.project.experiments.map(experiment => (
          <div
            className={`sub-list-item ${experiment.isReviewed ? "reviewed" : experiment.isPublished ? "published" : ""
              }`}
            key={experiment.id}
          >
            <div className="side-bar-sub-item">
              <Link
                to={"/projects/" + props.project.id + "/experiments/" + experiment.id}
                onClick={props.updateState}
                className="react-sub-link"
              >
                {experiment.title}
              </Link>
            </div>
          </div>
        )) : ""}
      </div>
    </Fragment>
  );
};

export default SideBarItem;
