import React, { useState, useEffect } from "react";
import { ButtonToolbar, Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux"
import { getUser, createLog } from "../../utils/apiCall";

const FileUploadModal = ({user, ...props}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const userName = `${user.FirstName} ${user.Lastname}`

  const onChange = e => {
    setUploadedFiles(e.target.files);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const data = new FormData();

    for (let i = 0; i < uploadedFiles.length; i++) {
      data.append("files", uploadedFiles[i]);
    }

    const res = await props.uploadFiles(data, userName);

    if (res !== undefined) {
      if (props.logFlag === 0) {
        await createLog(7, uploadedFiles);
      } else if (props.logFlag === 1) {
        await createLog(8, uploadedFiles);
      }
    }

    props.updateState();
  };

  return (
    <ButtonToolbar>
      <Button variant="primary" className="modal-button btn-sm" onClick={() => setModalShow(true)}>
        <i className="fas fa-upload" />
        <span> Upload Files</span>
      </Button>
      <Modal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="form-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="form-modal">
            <i className="fas fa-upload" /> Upload Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => onSubmit(e)} enctype="multipart/form-data">
            <Form.Group controlId="formDocumentType">
              <Form.Control id="file-upload" type="file" multiple onChange={e => onChange(e)} />
            </Form.Group>
            <Form.Group controlId="formDocumentTypeButton">
              <Button variant="primary" type="submit" onClick={() => setModalShow(false)}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </ButtonToolbar>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(FileUploadModal);
