import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";

const LogoutPopup = ({ logout }) => {
  const [signoutTime] = useState(3600 * 1000);
  const [warningTime] = useState(3500 * 1000);

  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    console.log("Inactivity warning");
  };

  const userLogout = () => {
    console.log("You have been loged out");
    logout();
    return <Redirect to="/" />;
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(userLogout, signoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <div></div>;
};

export default connect(null, { logout })(LogoutPopup);
