import React, { useState, useEffect, Fragment } from "react";
import axios from "../../constants/axiosRequest";
import SideBarHeader from "./SideBarHeader";
import { getProjects } from "../../utils/apiCall";

const SideBar = props => {
  const [projects, setProjects] = useState({});
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let projectsRes = await getProjects();
      projectsRes = projectsRes.filter(projectRes => projectRes.active);
      const experimentsRes = await axios.get(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`
      );

      let typesList = [];

      for (let project of projectsRes) {
        const experiments = experimentsRes.data.filter(experiment => experiment.projectId === project.id);
        project.experiments = experiments;

        if (!typesList.includes(project.type)) {
          typesList.push(project.type);
        }
      }

      setProjects(projectsRes);
      setProjectTypes(typesList);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="container-fluid p-0 mt-3">
      {!loading && (
        <div className="list">
          {projectTypes.map(type => (
            <SideBarHeader type={type} projects={projects} updateState={props.updateState} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SideBar;
