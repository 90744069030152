import React, { useState, useEffect, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../constants/axiosRequest";
import LoadTemplateModal from "../layout/LoadTemplateModal";
import Signature from "../layout/Signature";
import { getProject, getProjects, publishNotebook, reviewNotebook, getNotebook, getTemplates } from "../../utils/apiCall";
import SideBar from "../layout/SideBar";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const Notebook = ({ match, user }) => {
  const [experiment, setExperiment] = useState({});
  const [project, setProject] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publisher, setPublisher] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [reviewer, setReviewer] = useState("");
  const [isReviewed, setIsReviewed] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [updateState, setUpdateState] = useState(false);
  const [updateEditor, setUpdateEditor] = useState(false);
  const [docEditor, setDocEditor] = useState("");
  const [tzOption, setTzOption] = useState("");

  const url = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

  useEffect(() => {
    const fetchData = async () => {
      const project = await getProject(match.params.projectId);
      setProject(project);

      if (!project) {
        return
      }

      const experiment = await getNotebook(match.params.projectId, match.params.experimentId);
      setExperiment(experiment);

      if (!experiment) {
        return
      }

      const templates = await getTemplates();

      let projects = await getProjects();
      projects = projects.filter(project => project.active);
      const projectExperiments = await axios.get(
        `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`
      );
      let typesList = [];

      for (let project of projects) {
        const filteredExperiments = projectExperiments.data.filter(experiment => experiment.projectId === project.id);
        project.experiments = filteredExperiments;

        if (!typesList.includes(project.type)) {
          typesList.push(project.type);
        }
      }

      const timezoneOption = getTimezone(tzOption);
      
      setProjects(projects);
      setProjectTypes(typesList);
      setIsPublished(experiment.isPublished);
      setPublisher(experiment.publisher);
      setIsReviewed(experiment.isReviewed);
      setReviewer(experiment.reviewer);
      setTemplates(templates);
      setLoading(false);

      if (docEditor) {
        docEditor.destroyEditor();
      }

      if (experiment.documentType !== "pdf") {
        const userName = user.FirstName + " " + user.LastName;
        const config = {
          info: {
            owner: experiment.createdBy
          },
          document: {
            fileType: experiment.documentType,
            permissions: {
              edit: !experiment.isPublished,
              review: !experiment.isReviewed
            },
            title: experiment.title,
            url: url + "/files/projects/" + project.id + "/" + experiment.id + "." + experiment.documentType
          },
          editorConfig: {
            callbackUrl:
              url +
              "/track?fileName=" +
              experiment.id +
              "." +
              experiment.documentType +
              "&description=" +
              experiment.description +
              "&title=" +
              experiment.title +
              "&projectTitle=" +
              project.id +
              "&urlPath=" +
              window.location.href +
              "&userName=" +
              userName +
              "&token=" +
              localStorage.token +
              "&flag=" +
              0,
            customization: {
              // autosave: false,
              // forcesave: true,
              showReviewChanges: isReviewed
            },
            user: { name: userName }
          }
        };

        setDocEditor(new window.DocsAPI.DocEditor("placeholder", config));
      }
    };

    fetchData();
  }, [updateState]);

  useEffect(() => {
    if (docEditor) {
      docEditor.destroyEditor();
    }

    if (!experiment || !project) {
      return
    }

    if (experiment.documentType !== "pdf") {
      const userName = user.FirstName + " " + user.LastName;
      const config = {
        document: {
          fileType: experiment.documentType,
          permissions: {
            edit: !isPublished,
            review: !isReviewed
          },
          title: experiment.title,
          url: url + "/files/projects/" + project.id + "/" + experiment.id + "." + experiment.documentType
        },
        editorConfig: {
          callbackUrl:
            url +
            "/track?fileName=" +
            experiment.id +
            "." +
            experiment.documentType +
            "&description=" +
            experiment.description +
            "&title=" +
            experiment.title +
            "&projectTitle=" +
            project.id +
            "&urlPath=" +
            window.location.href +
            "&userName=" +
            userName +
            "&token=" +
            localStorage.token +
            "&flag=" +
            0,
          customization: {
            // autosave: false,
            // forcesave: true,
            showReviewChanges: isReviewed
          },
          user: { name: userName }
        }
      };

      setDocEditor(new window.DocsAPI.DocEditor("placeholder", config));
    }
  }, [updateEditor]);

  useEffect(() => {
    setTzOption(getTimezone(tzOption));
  }, [tzOption]);

  if (!project) {
    return <Redirect to="/projects" />;
  }

  if (!experiment) {
    return <Redirect to={"/projects/" + match.params.projectId + "/experiments"} />;
  }

  const onReviewClick = async () => {
    const projectId = match.params.projectId;
    const experimentId = match.params.experimentId;
    const userName = user.FirstName + " " + user.LastName;
    const dateTime = new Date();

    if (publisher === userName) {
      throw Error("A different user is required for reviewing.")
    }

    if (window.confirm("Are you sure you want to review this notebook?")) {
      setReviewer(userName);
      setIsReviewed(true);
      reviewNotebook(true, userName, dateTime, projectId, experimentId);
      setUpdateEditor(!updateEditor);
    }
  };

  const onPublishClick = () => {
    const projectId = match.params.projectId;
    const experimentId = match.params.experimentId;
    const userName = user.FirstName + " " + user.LastName;
    const dateTime = new Date();

    if (window.confirm("Are you sure you want to publish this notebook?")) {
      setPublisher(userName);
      setIsPublished(true);
      publishNotebook(true, userName, dateTime, projectId, experimentId);
      setUpdateEditor(!updateEditor);
    }
  };

  const publisherSignature = (
    <Fragment>
      Published by <strong>{publisher}</strong> on <strong>{formatTimezone(experiment.publishedAt ? experiment.publishedAt : new Date(), tzOption)}</strong>
    </Fragment>
  );

  const reviewerSignature = (
    <Fragment>
      Reviewed by <strong>{reviewer}</strong> on <strong>{formatTimezone(experiment.reviewedAt ? experiment.reviewedAt : new Date(), tzOption)}</strong>
    </Fragment>
  );

  const spinner = (
    <div className="d-flex justify-content-center">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {loading && spinner}
      {!loading && (
        <div className="container-fluid white-background">
          <div className="row">
            <div className="col-lg-2 hidden-md">
              <div className="side-bar">
                <SideBar projects={projects} projectTypes={projectTypes} updateState={() => setUpdateState(!updateState)} />
              </div>
            </div>
            <div className="col-12 col-lg-10 workspace">
              <div className="container-fluid">
                <nav className="navbar navbar-expand-xl navbar-light">
                  <h3>{experiment.title}</h3>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto ml-auto">
                      <li className="nav-item">
                        {experiment.documentType !== "pdf" && (
                          <LoadTemplateModal
                            handleEditor={() => setUpdateEditor(!updateEditor)}
                            templates={templates}
                            experiment={experiment}
                            projectId={match.params.projectId}
                            handleReload={() => setLoading(true)}
                            disabled={isPublished}
                          />
                        )}
                      </li>
                    </ul>
                    <ul className="navbar-nav">
                      <li className="nav-item mr-3">
                        <Link to="/projects" className="link">
                          <i className="fas fa-project-diagram" />
                          <span> Projects</span>
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <Link to="/templates" className="link">
                          <i className="fas fa-copy" />
                          <span> Templates</span>
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <Link to="/search" className="link">
                          <i className="fas fa-search" />
                          <span> Search</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/logs" className="link">
                          <i className="fas fa-list" />
                          <span> Activity Log</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="editor-wrapper">
                      <div id="placeholder"></div>
                    </div>
                  </div>
                  {experiment.documentType !== "pdf" && (
                    <div className="container-fluid">
                      <div className="row signature-button-wrapper">
                        <div className="col-3 col-lg-1 signature-button">
                          <button
                            className="btn btn-primary mt-1"
                            onClick={() => onReviewClick()}
                            disabled={!isPublished || isReviewed || publisher === user.FirstName + " " + user.LastName}
                          >
                            Review
                          </button>
                        </div>
                        <div className="col-3 col-lg-1 signature-button">
                          <button
                            className="btn btn-primary mt-1"
                            onClick={() => onPublishClick()}
                            disabled={isPublished}
                          >
                            Publish
                          </button>
                        </div>
                        <div className="col-3 col-lg-5 signature">
                          {isPublished && <Signature signature={publisherSignature} />}
                        </div>
                        <div className="col-3 col-lg-5 signature">
                          {isReviewed && <Signature signature={reviewerSignature} />}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

Notebook.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  tzOption: state.timezone.tzOption
});

export default connect(mapStateToProps)(Notebook);
