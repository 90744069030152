const axios = require('axios')

const logger = async (level, message) => {

  const body = { level: level, message: message };
  await axios.post(
    `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/clientLogger`,
    body
  );
};

export default logger;
