import React, { useState, useRef } from "react";
import { ButtonToolbar, Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux"
import { loadTemplate } from "../../utils/apiCall";

const LoadTemplateModal = ({user, ...props}) => {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    templateId: ""
  });
  const [isTemplate, setIsTemplate] = useState(false);
  const [options, setOptions] = useState(true);
  const input = useRef();
  const list = useRef();
  const { templateId } = formData;
  const userName = `${user.FirstName} ${user.Lastname}`

  const onClick = () => {
    setModalShow(true);
    let options = {};

    for (let i = 0; i < props.templates.length; i++) {
      options[props.templates[i].title.toLowerCase()] = props.templates[i].id;
    }

    setOptions(options);
  };


  const onChange = () => {
    const inputValue = input.current.value.toLowerCase();
    const name = input.current.name;
    const titles = Object.keys(options);

    if (titles.includes(inputValue)) {
      setFormData({ ...formData, [name]: options[inputValue] });
      setIsTemplate(true);
    } else {
      setFormData({ ...formData, [name]: null });
      setIsTemplate(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setModalShow(false);

    const template = await loadTemplate(
      templateId,
      props.experiment.documentType,
      props.projectId,
      props.experiment.id,
      userName
    );

    // const templateTitleArr = templates.map(template => template.title);
    // const templateIdArr = templates.map(template => template.id);

    // if (templateIdArr !== undefined) {
    //   await createLog(8, templateTitleArr, templateIdArr);
    // }

    setFormData("");
    props.handleEditor();
  };

  return (
    <ButtonToolbar>
      <Button variant="primary" className="modal-button btn-sm" disabled={props.disabled} onClick={() => onClick()}>
        <i className="fas fa-plus" /> {" Load Template"}
      </Button>
      <Modal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="form-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="form-modal">{"Load Template"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group controlId="formDocumentType">
              <Form.Label>Choose Template</Form.Label>
              <input
                type="text"
                list="templates"
                name="templateId"
                placeholder="Type or pick from list"
                data-value={formData.templateId}
                ref={input}
                onChange={() => onChange()}
              />
              <datalist id="templates" ref={list}>
                {props.templates.map((template) => {
                  if (template.documentType[0] === props.experiment.documentType[0]) {
                    return <option data-value={template.id} value={template.title} />;
                  }
                })}
              </datalist>
              {/* <Form.Control as="select" name="templateId" value={formData.templateId} onChange={e => onChange(e)}>
                {props.templates.map(template => {
                  if (template.documentType[0] === props.experiment.documentType[0]) {
                    return <option value={template.id}>{template.title}</option>;
                  }
                })}
              </Form.Control> */}
            </Form.Group>
            <Container>
              <Row>
                <Col>
                  <Button variant="primary" type="submit" disabled={!isTemplate}>
                    Load
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => setModalShow(false)}>Close</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </ButtonToolbar>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(LoadTemplateModal);
