import React from "react";
import { Redirect } from "react-router-dom";

const LimsLogin = () => {
  const loginRedirect = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const url = params.get("url");
    window.location = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/login?RelayState=${url}`
    /*if (token) {
      localStorage.setItem("token", token);
      return <Redirect to={url} />;
    } else {
      return <Redirect to="/login" />;
    }*/
  };

  return loginRedirect();
};

export default LimsLogin;
